import { type PageProps } from 'gatsby'
import * as React from 'react'
import { Layout } from '../../components/Layout'
import { MarriageRings } from '../../components/MarriageRings'
import { SEO } from '../../components/SEO'
import { useMarriageRingParams } from '../../hooks/useMarriageRingParams'

export const Head = () => (
    <SEO
        title="安い結婚指輪・激安のマリッジリングなら低価格で高品質なミスプラチナ"
        description="低価格の結婚指輪はメーカー直販ミスプラチナがおすすめ。プラチナのペアリング一覧。"
        page="marriagering"
    />
)

const MarriageRingPage: React.FC<PageProps> = ({ location }) => {
    const params = useMarriageRingParams(location.search)
    return (
        <Layout breadcrumbs={[{ name: '安い結婚指輪・マリッジリング' }]}>
            <MarriageRings {...params} />
        </Layout>
    )
}

export default MarriageRingPage
